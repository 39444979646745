@font-face { 
    font-family: Caveat; 
    src: url('../src/font/Caveat-Regular.ttf');
} 

body {
  margin: 0;
  background-color: #acf1f1;
  font-family: Caveat, sans-serif;
}
